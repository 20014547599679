import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Link } from 'gatsby'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Card, Container, Figure, Jumbotron } from 'react-bootstrap'
import { RootState } from 'redux/store'
import ActivityNotification from 'components/dashboard/ActivityNotification'
const DashboardPage: React.FC = () => {
	const notifications = useSelector((state: RootState) => state.UserReducer)

	const links = [
		{
			name: 'Courses',
			to: '/user/courses',
			details: 'View all your courses.',
			img: '/img/course.jpg',
		},
		{
			name: 'Activities',
			to: '/tracking',
			details: 'View activities.',
			img: '/img/activities.jpg',
		},
		{
			name: 'Calendar',
			to: '/calendar',
			details: 'Calendar.',
			img: '/img/calendar.jpg',
		},
		{
			name: 'Files',
			to: '/files',
			details: 'Files.',
			img: '/img/files.jpg',
		},
		{
			name: 'Notifications',
			to: '/notification',
			details: 'Notifications',
			img: '/img/files.jpg',
		},
	]

	const [quoteData, setQuoteData] = useState<{ quote: string; from: string }>()
	useEffect(() => {
		const run = async () => {
			const data = await handleApi(axios.get<{ quote: string; from: string }>(getUrl('quote')))
			if (data) {
				setQuoteData(data)
			}
		}
		run()
	}, [])

	return (
		<>
			<div className='container'>
				<div className='mobile-hide'>
					<h4>Quote of the Day:</h4>
					<h5>{quoteData?.quote}</h5>
					<h6>
						{' '}
						<em> -{quoteData?.from}</em>
					</h6>
				</div>
				{/* <ActivityNotification></ActivityNotification> */}
				<div className='courses-page mx-2'>
					{
						<>
							<h1></h1>
							<div className=' mt-4 mb-4'>
								<div className='row'>
									{links.map((elem) => (
										<>
											<Col lg={3} md={4}>
												<Link to={`${elem.to}`}>
													<Card className='classCard'>
														<Jumbotron
															className='classJumbotron px-0'
															style={{
																// backgroundColor:
																backgroundImage: `url(${elem.img ?? '/card.jpg'})`,
																// 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.12) 100%)',
															}}
														>
															<Container className='mt-2'>
																<Row className='align-items-center '>
																	{/* <Col xs='10'>
																		<h4 className='subName text-dark'>{elem.name}</h4>
																	</Col> */}

																	<Col xs={2}>
																		<a href='/classes' className='text-right text-white '>
																			<i className='bx bx-dots-vertical-rounded float-right'></i>
																		</a>
																	</Col>
																</Row>
																<Row className='align-items-center '>
																	<Col>{/* <p className='text-light'>{teacher}</p> */}</Col>
																</Row>
															</Container>
															<Figure className=' float-right pr-3'>
																{/* <Figure.Image src={ProfileImage} className=' rounded-circle classImg' alt='' /> */}
															</Figure>
														</Jumbotron>
														<Card.Body>
															<Row>
																<Col>
																	<h3 style={{ padding: 0, margin: 0 }} className='text-dark'>
																		{elem.name}
																	</h3>
																	{/* <br />
																	<h6>{elem.details}</h6> */}
																	{/* <div className='toDoList'>
																	<ul>
																		<li>
																			<a h6ef='/assignment' className='card-text text-dark m-0'>
																				Assignment 2
																			</a>
																		</li>
																		<li>
																			<a href='/quiz' className='card-text text-dark m-0'>
																				Quiz 2
																			</a>
																		</li>
																	</ul>
																</div> */}
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Link>
											</Col>
										</>
									))}
								</div>
							</div>
						</>
					}
				</div>
			</div>
		</>
	)
}

export default DashboardPage
